export const typeFaceObjs = [
  { label: '活字体', value: 'sans-serif' }, //ゴシック体
  { label: '筆記体', value: 'cursive' }, // 筆記体
  { label: '漢字', value: 'serif' } // 明朝体
]
export const typeFaceEnAllObjs = [
  { label: 'Gothic', value: 'sans-serif' }, //ゴシック体
  // { label: 'Cursive', value: 'cursive' }, // 筆記体
  { label: 'Brush', value: 'Brush Script MT' }, // 筆記体 ブラッシュ
  { label: 'Kanji(block style)', value: 'serif' } // 明朝体
]

export const positionObjs = [
  { label: '親指部分', value: 'thumbFinger' },
  { label: '小指部分', value: 'childFinger' },
  { label: '小指側背面', value: 'pinkyFingerSideBack' },
  { label: '裏革部分(1段目)', value: 'leatherLiningFirst' },
  { label: '裏革部分(2段目)', value: 'leatherLiningSecond' }
]
export const positionEnObjs = [
  { label: 'Thumb', value: 'thumbFinger' },
  { label: 'Pinky', value: 'childFinger' },
  { label: 'Palm liner (1 step)', value: 'leatherLiningFirst' },
  { label: 'Palm liner (2 step)', value: 'leatherLiningSecond' }
]

export const colorObjs = [
  { label: 'ゴールド', value: 'gold', color: '#D9A948' },
  { label: 'シルバー', value: 'silver', color: '#A7A8A7' },
  { label: 'ライトゴールド', value: 'lightGold', color: '#D7B070' },
  { label: 'ライトシルバー', value: 'lightSilver', color: '#DFDAD4' },
  { label: 'コルク', value: 'cork', color: '#A18555' },
  { label: 'チョコレート', value: 'chocolate', color: '#6F4A35' },
  { label: 'イエロー', value: 'yellow', color: '#F3CD54' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#FFF059' },
  { label: 'オレンジ', value: 'orange', color: '#CF7D43' },
  { label: 'レッド', value: 'red', color: '#A03D38' },
  { label: 'ピンク', value: 'pink', color: '#DE949A' },
  { label: 'パープル', value: 'purple', color: '#754F6E' },
  { label: 'グリーン', value: 'green', color: '#719B4F' },
  { label: 'スカイブルー', value: 'skyBlue', color: '#0096C9' },
  { label: 'ブルー', value: 'royalBlue', color: '#305173' },
  { label: 'ネイビー', value: 'navy', color: '#2C3F67' },
  { label: 'ブラック', value: 'black', color: '#1A1919' },
  { label: 'ホワイト', value: 'white', color: '#F6F3F0' }
]
export const colorEnObjs = [
  { label: 'Gold', value: 'gold', color: '#D9A948' },
  { label: 'Silver', value: 'silver', color: '#A7A8A7' },
  { label: 'Light Gold', value: 'lightGold', color: '#D7B070' },
  { label: 'Light Silver', value: 'lightSilver', color: '#DFDAD4' },
  { label: 'Cork', value: 'cork', color: '#A18555' },
  { label: 'Chocolate', value: 'chocolate', color: '#6F4A35' },
  { label: 'Yellow', value: 'yellow', color: '#F3CD54' },
  { label: 'Lemon Yellow', value: 'lemonYellow', color: '#FFF059' },
  { label: 'Orange', value: 'orange', color: '#CF7D43' },
  { label: 'Red', value: 'red', color: '#A03D38' },
  { label: 'Pink', value: 'pink', color: '#DE949A' },
  { label: 'Purple', value: 'purple', color: '#754F6E' },
  { label: 'Green', value: 'green', color: '#719B4F' },
  { label: 'Sky Blue', value: 'skyBlue', color: '#0096C9' },
  { label: 'Blue', value: 'royalBlue', color: '#305173' },
  { label: 'Navy', value: 'navy', color: '#2C3F67' },
  { label: 'Black', value: 'black', color: '#1A1919' },
  { label: 'White', value: 'white', color: '#F6F3F0' }
]

export const shadowEdgeColorObjs = [{ label: '不要', value: 'none' }, ...colorObjs]
export const shadowEdgeColorEnObjs = [{ label: 'None', value: 'none' }, ...colorEnObjs]

export const en2JaEmbroideryPartsTranslates = [
  { en: 'None', japanese: '不要' },
  { en: 'Gold', japanese: 'ゴールド' },
  { en: 'Silver', japanese: 'シルバー' },
  { en: 'Light Gold', japanese: 'ライトゴールド' },
  { en: 'Light Silver', japanese: 'ライトシルバー' },
  { en: 'Cork', japanese: 'コルク' },
  { en: 'Chocolate', japanese: 'チョコレート' },
  { en: 'Yellow', japanese: 'イエロー' },
  { en: 'Lemon Yellow', japanese: 'レモンイエロー' },
  { en: 'Orange', japanese: 'オレンジ' },
  { en: 'Red', japanese: 'レッド' },
  { en: 'Pink', japanese: 'ピンク' },
  { en: 'Purple', japanese: 'パープル' },
  { en: 'Green', japanese: 'グリーン' },
  { en: 'Sky Blue', japanese: 'スカイブルー' },
  { en: 'Blue', japanese: 'ブルー' },
  { en: 'Navy', japanese: 'ネイビー' },
  { en: 'Black', japanese: 'ブラック' },
  { en: 'White', japanese: 'ホワイト' },
  { en: 'Thumb', japanese: '親指部分' },
  { en: 'Pinky', japanese: '小指部分' },
  { en: 'Little', japanese: '小指部分' },
  { en: 'Palm liner (1 step)', japanese: '裏革部分(1段目)' },
  { en: 'Palm liner (2 step)', japanese: '裏革部分(2段目)' },
  { en: 'Gothic', japanese: '活字体' },
  { en: 'sans-serif', japanese: '活字体' },
  { en: 'Brush', japanese: '筆記体' },
  { en: 'cursive', japanese: '筆記体' },
  { en: 'Kanji(block style)', japanese: '漢字(楷書体)' }
]
