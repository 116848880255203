import { State, Action } from 'src/types'
import {
  SET_SELECTED_PARTS,
  SET_ALL_LEATHER_COLOR,
  SET_BASE_MODEL,
  SET_DOMINANT_ARM,
  SET_SIZE,
  SET_BACK_LACE,
  SET_MOUTON_COLOR,
  SET_WEB_PARTS,
  SET_LEATHER_COLOR,
  SET_EDGE_COLOR,
  SET_LININGS,
  SET_HAMIDASHI,
  SET_FINGER_GUARD_TYPE,
  SET_LACE_COLOR,
  SET_STITCH_COLOR,
  SET_EMBROIDERIES,
  ADD_EMBROIDERY,
  REMOVE_EMBROIDERY,
  SET_WEB_COLOR,
  SET_THUMB_HOOK,
  SET_LITTLE_HOOK,
  SET_INDEX_COVER_COLOR,
  SET_PERSONAL,
  SET_THUMB_OUT_COLOR,
  SET_THUMB_WEB_COLOR,
  SET_INDEX_WEB_COLOR,
  SET_INDEX_MIDDLE_COLOR,
  SET_MIDDLE_INDEX_COLOR,
  SET_MIDDLE_RING_COLOR,
  SET_RING_MIDDLE_COLOR,
  SET_RING_LITTLE_COLOR,
  SET_LITTLE_RING_COLOR,
  SET_LITTLE_OUT_COLOR,
  SET_LIST_BELT_COLOR,
  SET_THUMB_WEB_AND_INDEX_WEB,
  SET_TANNER,
  SET_THUMB_ROOT_COLOR,
  SET_AHN_JAE_SEOK,
  SET_SHIN_JUNG_WOO,
  SET_PARK_GE_BUM,
  SET_HAFN_DONG_HEE,
  SET_ATOMS_LABEL,
  SET_WEB_COLOR_2
} from 'src/features/brands/atoms/korea/constants/actionType'
import { liningColorKoObjs } from 'src/features/brands/atoms/korea/constants/colors'
import { baseInitialState, initialPersonalState, unselectedStateKo, unselectedColorStateKo } from 'src/features/brands/reducer'

const colorInitialStateKo = {
  parts: { label: '全体', value: 'all' },
  all: unselectedColorStateKo,
  catchFace: unselectedColorStateKo,
  edge: unselectedColorStateKo,
  lace: unselectedColorStateKo,
  linings: unselectedColorStateKo,
  hamidashi: unselectedColorStateKo,
  stitch: unselectedColorStateKo,
  web: unselectedColorStateKo,
  web2: unselectedColorStateKo,
  thumbHook: unselectedColorStateKo,
  littleHook: unselectedColorStateKo,
  listBelt: unselectedColorStateKo,
  indexCover: unselectedColorStateKo,
  mouton: unselectedColorStateKo,
  atomsLabel: unselectedStateKo
}
const gloveColorInitialState = {
  thumbOut: unselectedColorStateKo,
  thumbWeb: unselectedColorStateKo,
  thumbRoot: unselectedColorStateKo,
  thumbWebAndIndexWeb: unselectedColorStateKo,
  thumbIndexMiddle: unselectedColorStateKo,
  indexWeb: unselectedColorStateKo,
  indexMiddle: unselectedColorStateKo,
  indexLeftMiddleRight: unselectedColorStateKo,
  middleIndex: unselectedColorStateKo,
  middleLeftRingRight: unselectedColorStateKo,
  middleRing: unselectedColorStateKo,
  ringMiddle: unselectedColorStateKo,
  ringLeftLittleRight: unselectedColorStateKo,
  ringLittle: unselectedColorStateKo,
  littleRing: unselectedColorStateKo,
  littleOut: unselectedColorStateKo
}
const initialEmbroideryState = {
  id: 0,
  typeFace: { label: '', value: '' },
  position: { label: '', value: '' },
  color: { label: '', value: '', color: '' },
  shadowColor: { label: '', value: 'none' },
  edgeColor: { label: '', value: 'none' },
  content: ''
}

export const initialInfielderStateKo: State = {
  ...baseInitialState,
  ...colorInitialStateKo,
  ...gloveColorInitialState,
  tanner: unselectedStateKo,
  backLace: unselectedStateKo,
  webParts: unselectedStateKo,
  mouton: unselectedStateKo,
  atomsLabel: unselectedStateKo,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}

export const infieldReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_TANNER:
      return { ...state, tanner: action.tanner }
    case SET_SELECTED_PARTS:
      return { ...state, parts: action.parts }
    case SET_ALL_LEATHER_COLOR:
      return {
        ...state,
        all: action.all,
        catchFace: action.all,
        web: action.all,
        web2: action.all,
        thumbHook: action.all,
        littleHook: action.all,
        edge: action.all,
        indexCover: action.all,
        listBelt: action.all,
        // 裏革にはない色が「全体」として指定されたら、裏革のみ既存stateを維持させる
        // 裏革にもある色が「全体」として指定されたらもちろん反映を許可
        linings: liningColorKoObjs.some((x) => x.color === action.all.color) ? action.all : state.linings, // 裏革
        // 内野手用グラブ
        thumbOut: action.all,
        thumbWeb: action.all,
        thumbRoot: action.all,
        thumbWebAndIndexWeb: action.all,
        indexWeb: action.all,
        indexMiddle: action.all,
        middleIndex: action.all,
        middleRing: action.all,
        ringMiddle: action.all,
        ringLittle: action.all,
        littleRing: action.all,
        littleOut: action.all
      }
    case SET_BASE_MODEL:
      return { ...state, baseModel: action.baseModel }
    case SET_DOMINANT_ARM:
      return { ...state, dominantArm: action.dominantArm }
    case SET_SIZE:
      return { ...state, size: action.size }
    case SET_BACK_LACE:
      return { ...state, backLace: action.backLace }
    case SET_WEB_PARTS:
      return { ...state, webParts: action.webParts }
    case SET_ATOMS_LABEL:
      return { ...state, atomsLabel: action.atomsLabel }
    case SET_LEATHER_COLOR:
      return { ...state, catchFace: action.catchFace }
    case SET_EDGE_COLOR:
      return { ...state, edge: action.edge }
    case SET_WEB_COLOR: {
      const isNeverSetWeb2 = state?.web2?.value === 'unselected'
      return isNeverSetWeb2 ? { ...state, web: action.web, web2: action.web } : { ...state, web: action.web }
    }
    case SET_WEB_COLOR_2:
      return { ...state, web2: action.web2 }
    // 内野手用グラブ
    case SET_THUMB_OUT_COLOR:
      return { ...state, thumbOut: action.thumbOut }
    case SET_THUMB_WEB_COLOR:
      return { ...state, thumbWeb: action.thumbWeb }
    case SET_THUMB_ROOT_COLOR:
      return { ...state, thumbRoot: action.thumbRoot }
    case SET_THUMB_WEB_AND_INDEX_WEB:
      return { ...state, thumbWebAndIndexWeb: action.thumbWebAndIndexWeb }
    case SET_INDEX_WEB_COLOR:
      return { ...state, indexWeb: action.indexWeb }
    case SET_INDEX_MIDDLE_COLOR:
      return { ...state, indexMiddle: action.indexMiddle }
    case SET_MIDDLE_INDEX_COLOR:
      return { ...state, middleIndex: action.middleIndex }
    case SET_MIDDLE_RING_COLOR:
      return { ...state, middleRing: action.middleRing }
    case SET_RING_MIDDLE_COLOR:
      return { ...state, ringMiddle: action.ringMiddle }
    case SET_RING_LITTLE_COLOR:
      return { ...state, ringLittle: action.ringLittle }
    case SET_LITTLE_RING_COLOR:
      return { ...state, littleRing: action.littleRing }
    case SET_LITTLE_OUT_COLOR:
      return { ...state, littleOut: action.littleOut }
    case SET_LIST_BELT_COLOR:
      return { ...state, listBelt: action.listBelt }
    case SET_LININGS:
      return { ...state, linings: action.linings }
    case SET_HAMIDASHI:
      return { ...state, hamidashi: action.hamidashi }
    case SET_FINGER_GUARD_TYPE:
      return { ...state, fingerGuardType: action.fingerGuardType }
    case SET_LACE_COLOR:
      return { ...state, lace: action.lace }
    case SET_STITCH_COLOR:
      return { ...state, stitch: action.stitch }
    case SET_MOUTON_COLOR:
      return { ...state, mouton: action.mouton }
    case SET_EMBROIDERIES:
      return { ...state, embroideries: action.embroideries }
    case ADD_EMBROIDERY: // incrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case REMOVE_EMBROIDERY: // decrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case SET_THUMB_HOOK:
      return { ...state, thumbHook: action.thumbHook }
    case SET_LITTLE_HOOK:
      return { ...state, littleHook: action.littleHook }
    case SET_INDEX_COVER_COLOR:
      return { ...state, indexCover: action.indexCover }
    case SET_PERSONAL:
      return { ...state, personal: action.personal }
    case SET_AHN_JAE_SEOK:
      return {
        ...initialInfielderStateKo,
        ...state,
        dominantArm: { label: '미선택', value: 'unselected' },
        size: { label: '미선택', value: 'unselected' },
        backLace: { label: '미선택', value: 'unselected' },
        fingerGuardType: { label: '미선택', value: 'unselected' },
        baseModel: action.baseModel,
        all: action.all,
        catchFace: action.catchFace,
        web: action.web,
        web2: action.web2,
        thumbHook: action.thumbHook,
        littleHook: action.littleHook,
        indexCover: action.all,
        listBelt: action.all,
        thumbOut: action.all,
        thumbWeb: action.thumbWeb,
        thumbWebAndIndexWeb: action.all,
        indexWeb: action.indexWeb,
        indexMiddle: action.all,
        middleIndex: action.all,
        middleRing: action.middleRing,
        ringMiddle: action.ringMiddle,
        ringLittle: action.all,
        littleRing: action.all,
        littleOut: action.littleOut,
        linings: action.linings,
        edge: action.edge,
        lace: action.lace,
        stitch: action.stitch,
        hamidashi: action.hamidashi,
        webParts: action.webParts,
        bankLaceDirection: action.bankLaceDirection,
        // backLace: action.backLace,
        mouton: action.mouton,
        atomsLabel: action.atomsLabel
      }

    case SET_SHIN_JUNG_WOO:
      return {
        ...initialInfielderStateKo,
        ...state,
        baseModel: action.baseModel,
        dominantArm: { label: '미선택', value: 'unselected' },
        size: { label: '미선택', value: 'unselected' },
        backLace: { label: '미선택', value: 'unselected' },
        all: action.all,
        web: action.web,
        web2: action.web2,
        catchFace: action.catchFace,
        linings: action.linings,
        listBelt: action.listBelt,
        thumbHook: action.thumbHook,
        littleHook: action.littleHook,
        thumbOut: action.all,
        thumbWeb: action.all,
        thumbWebAndIndexWeb: action.all,
        indexWeb: action.indexWeb,
        indexMiddle: action.all,
        middleIndex: action.middleIndex,
        middleRing: action.middleRing,
        ringMiddle: action.ringMiddle,
        ringLittle: action.ringLittle,
        littleRing: action.littleRing,
        littleOut: action.littleOut,
        edge: action.edge,
        lace: action.lace,
        stitch: action.stitch,
        hamidashi: action.hamidashi,
        mouton: action.mouton,
        webParts: action.webParts,
        fingerGuardType: action.fingerGuardType,
        bankLaceDirection: action.bankLaceDirection,
        // size: action.size,
        // backLace: action.backLace,
        atomsLabel: action.atomsLabel
      }
    case SET_PARK_GE_BUM:
      return {
        ...initialInfielderStateKo,
        ...state,
        baseModel: action.baseModel,
        dominantArm: { label: '미선택', value: 'unselected' },
        size: { label: '미선택', value: 'unselected' },
        backLace: { label: '미선택', value: 'unselected' },
        fingerGuardType: { label: '미선택', value: 'unselected' },
        all: action.all,
        catchFace: action.all,
        web: action.all,
        web2: action.web2,
        thumbHook: action.thumbHook,
        littleHook: action.thumbHook,
        indexCover: action.all,
        listBelt: action.all,
        thumbOut: action.all,
        thumbWeb: action.all,
        thumbWebAndIndexWeb: action.all,
        indexWeb: action.all,
        indexMiddle: action.all,
        middleIndex: action.all,
        middleRing: action.all,
        ringMiddle: action.all,
        ringLittle: action.all,
        littleRing: action.all,
        littleOut: action.all,
        linings: action.linings,
        edge: action.edge,
        lace: action.lace,
        stitch: action.stitch,
        hamidashi: action.hamidashi,
        mouton: action.mouton,
        webParts: action.webParts,
        backStyle: action.backStyle,
        // backLace: action.backLace,
        atomsLabel: action.atomsLabel
      }
    case SET_HAFN_DONG_HEE:
      return {
        ...initialInfielderStateKo,
        ...state,
        baseModel: action.baseModel,
        dominantArm: { label: '미선택', value: 'unselected' },
        size: { label: '미선택', value: 'unselected' },
        backLace: { label: '미선택', value: 'unselected' },
        all: action.all,
        catchFace: action.catchFace,
        web: action.web,
        web2: action.web2,
        thumbHook: action.all,
        littleHook: action.all,
        indexCover: action.all,
        listBelt: action.listBelt,
        thumbOut: action.all,
        thumbWeb: action.thumbWeb,
        indexWeb: action.indexWeb,
        indexMiddle: action.indexMiddle,
        middleIndex: action.middleIndex,
        middleRing: action.middleRing,
        ringLittle: action.ringLittle,
        ringMiddle: action.ringMiddle,
        littleRing: action.littleRing,
        littleOut: action.littleOut,
        linings: action.linings,
        edge: action.edge,
        lace: action.lace,
        stitch: action.stitch,
        hamidashi: action.hamidashi,
        webParts: action.webParts,
        fingerGuardType: action.fingerGuardType,
        // size: action.size,
        materialPack: action.materialPack,
        mouton: action.mouton,
        backStyle: action.backStyle,
        // backLace: action.backLace,
        atomsLabel: action.atomsLabel
      }

    default:
      return state
  }
}
