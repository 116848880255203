export const typeFaceKoObjs = [
  // { label: '고딕체', value: 'sans-serif' }, //ゴシック体
  // { label: '필기체', value: 'cursive' }, // 筆記体
  // { label: '한자(해서체)', value: 'serif' } // 明朝体
  { label: '고딕체', value: 'Verdana, Arial, Helvetica, sans-serif', imageUrl: '/embroidery/font/sansSerif.jpg' }, //ゴシック体
  {
    label: '필기체',
    value: 'cursive, Pacifico, Quicksand, Lobster, Shadows Into Light, Attraction, Dancing Script, Allura, Tangerine',
    imageUrl: '/embroidery/font/cursive.jpg'
  }, // 筆記体
  { label: '한자(해서체)', value: '游明朝, Yu Mincho,YuMincho, Hiragino Mincho Pro,serif', imageUrl: '/embroidery/font/serif.jpg' } // 明朝体
]

export const positionKoObjs = [
  { label: '엄지', value: 'thumbFinger' },
  { label: '소지', value: 'childFinger' },
  { label: '내피 (1단)', value: 'leatherLiningFirst' },
  { label: '내피 (2단)', value: 'leatherLiningSecond' }
]

export const colorKoObjs = [
  { label: '골드', value: 'gold', color: '#D9A948' },
  { label: '실버', value: 'silver', color: '#A7A8A7' },
  { label: '라이트골드', value: 'lightGold', color: '#D7B070' },
  { label: '라이트실버', value: 'lightSilver', color: '#DFDAD4' },
  { label: '코르크', value: 'cork', color: '#A18555' },
  { label: '초콜릿', value: 'chocolate', color: '#6F4A35' },
  { label: '옐로우', value: 'yellow', color: '#F3CD54' },
  { label: '레몬 옐로우', value: 'lemonYellow', color: '#FFF059' },
  { label: '오렌지', value: 'orange', color: '#CF7D43' },
  { label: '레드', value: 'red', color: '#A03D38' },
  { label: '핑크', value: 'pink', color: '#DE949A' },
  { label: '퍼플', value: 'purple', color: '#754F6E' },
  { label: '그린', value: 'green', color: '#719B4F' },
  { label: '스카이블루', value: 'skyBlue', color: '#0096C9' },
  { label: '블루', value: 'royalBlue', color: '#305173' },
  { label: '네이비', value: 'navy', color: '#2C3F67' },
  { label: '블랙', value: 'black', color: '#1A1919' },
  { label: '화이트', value: 'white', color: '#F6F3F0' }
]

export const shadowEdgeColorKoObjs = [{ label: '불필요', value: 'none' }, ...colorKoObjs]

export const ko2JaEmbroideryPartsTranslates = [
  { korea: '서체', japanese: '書体' },
  { korea: '위치', japanese: '位置' },
  { korea: '위치.', japanese: '位置' }, // bug対策
  { korea: '자수 컬러', japanese: '刺繍カラー' },
  { korea: '그림자 컬러', japanese: '影カラー' },
  { korea: '테두리 컬러', japanese: 'フチカラー' },
  { korea: '자수 내용', japanese: '刺繍内容' },
  { korea: '고딕체', japanese: '活字体' },
  { korea: '필기체', japanese: '筆記体' },
  { korea: '한자(해서체)', japanese: '漢字(楷書体)' },
  { korea: '엄지', japanese: '親指部分' },
  { korea: '소지', japanese: '小指部分' },
  { korea: '내피 (1단)', japanese: '裏革部分(1段目)' },
  { korea: '내피 (2단)', japanese: '裏革部分(2段目)' },
  { korea: '골드', japanese: 'ゴールド' },
  { korea: '실버', japanese: 'シルバー' },
  { korea: '라이트골드', japanese: 'ライトゴールド' },
  { korea: '라이트실버', japanese: 'ライトシルバー' },
  { korea: '코르크', japanese: 'コルク' },
  { korea: '초콜릿', japanese: 'チョコレート' },
  { korea: '옐로우', japanese: 'イエロー' },
  { korea: '엘로우', japanese: 'イエロー' },
  { korea: '레몬 옐로우', japanese: 'レモンイエロー' },
  { korea: '오렌지', japanese: 'オレンジ' },
  { korea: '레드', japanese: 'レッド' },
  { korea: '핑크', japanese: 'ピンク' },
  { korea: '퍼플', japanese: 'パープル' },
  { korea: '그린', japanese: 'グリーン' },
  { korea: '스카이블루', japanese: 'スカイブルー' },
  { korea: '블루', japanese: 'ロイヤルブルー' },
  { korea: '네이비', japanese: 'ネイビー' },
  { korea: '블랙', japanese: 'ブラック' },
  { korea: '화이트', japanese: 'ホワイト' },
  { korea: '불필요', japanese: 'なし' }
]
