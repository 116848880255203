export const leatherColorObjesByTanner = {
  juteru: [
    { label: 'Jコルク', value: 'jCork', color: '#CE7220' },
    { label: 'Jブラック', value: 'jBlack', color: '#1E1E1E' },
    { label: 'Jレッドオレンジ', value: 'jRedOrange', color: '#FF362E' },
    { label: 'Jオレンジ', value: 'jOrange', color: '#FF6C23' }
  ],
  urakami: [
    { label: 'Uコルク', value: 'uCork', color: '#CE7220' },
    { label: 'Uブラック', value: 'uBlack', color: '#1E1E1E' },
    { label: 'Uレッドオレンジ', value: 'uRedOrange', color: '#FF362E' },
    { label: 'Uオレンジ', value: 'uOrange', color: '#FF6C23' },
    { label: 'UCブラウン', value: 'uCamelBrown', color: '#D7CEA5' }
  ],
  premiumSteer: [
    { label: 'コルク', value: 'cork', color: '#CE7220' },
    { label: 'ブラック', value: 'black', color: '#1E1E1E' },
    { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
    { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
    { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' }
  ],
  southSteer: [
    { label: 'コルク', value: 'cork', color: '#CE7220' },
    { label: 'ブラック', value: 'black', color: '#1E1E1E' },
    { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
    { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
    { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' }
  ],
  northSteer: [
    { label: 'コルク', value: 'cork', color: '#CE7220' },
    { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
    { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
    { label: 'ブラック', value: 'black', color: '#1E1E1E' },
    { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' }
    // { label: 'ティール', value: 'tealGreen', color: '#317290' },
    // { label: 'ネイビー', value: 'navy', color: '#0d194a' }
  ],
  k: [
    { label: 'Kコルク', value: 'kCork', color: '#CE7220' },
    { label: 'Kブラック', value: 'kBlack', color: '#1E1E1E' },
    { label: 'Kレッドオレンジ', value: 'kRedOrange', color: '#FF362E' },
    { label: 'Kオレンジ', value: 'kOrange', color: '#FF6C23' },
    { label: 'KCブラウン', value: 'kCamelBrown', color: '#D7CEA5' }
  ],
  terada: [
    { label: 'コルク', value: 'cork', color: '#CE7220' },
    { label: 'ブラック', value: 'black', color: '#1E1E1E' },
    { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
    { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
    { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' }
  ]
}
export const softTannerObjs = [{ label: 'Nine States Leather', value: 'nineStates' }]

export const leatherColorObjsJa = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#0d194a' },
  { label: 'ブルー', value: 'blue', color: '#0d42b5' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'マディグラス', value: 'mardiGras', color: '#4e734c' },
  { label: 'ティール', value: 'tealGreen', color: '#317290' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'ホワイト', value: 'white', color: '#fff' },
  { label: 'グレー', value: 'gray', color: '#8A8C8C' }
]
const premiumLeatherColorObjsJa = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' }
]
const southLeatherColorObjsJa = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#0d194a' },
  { label: 'ブルー', value: 'blue', color: '#0d42b5' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'マディグラス', value: 'mardiGras', color: '#4e734c' },
  { label: 'ティール', value: 'tealGreen', color: '#317290' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'ホワイト', value: 'white', color: '#fff' },
  { label: 'グレー', value: 'gray', color: '#8A8C8C' }
]
const northLeatherColorObjsJa = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' }
]

const nineStatesLeatherColorObjsJa = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#0d194a' },
  { label: 'ブルー', value: 'blue', color: '#0d42b5' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'マディグラス', value: 'mardiGras', color: '#4e734c' },
  { label: 'ティール', value: 'tealGreen', color: '#317290' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'ホワイト', value: 'white', color: '#fff' },
  { label: 'グレー', value: 'gray', color: '#8A8C8C' }
]
export const liningColorObjs = [...leatherColorObjsJa]
export const laceColorObjs = [
  { label: 'グレー', value: 'gray', color: '#8A8C8C' },
  { label: 'ホワイト', value: 'white', color: '#fff' },
  { label: '中白ブラック', value: 'materialBlack', color: '#282925' },
  { label: 'ブラック', value: 'black', color: '#282925' },
  { label: '中白レッドオレンジ', value: 'materialRedOrange', color: '#FF362E' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: '中白オレンジ', value: 'materialOrange', color: '#FF6C23' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'コルク', value: 'kurk', color: '#CE7220' },
  { label: 'チョコレート', value: 'chocolate', color: '#70352e' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#202742' },
  { label: 'ブルー', value: 'blue', color: '#193B85' },
  { label: 'Cブラウン', value: 'brown', color: '#D7CEA5' },
  { label: 'グリーン', value: 'green', color: '#407D66' }
]
export const stitchColorObjs = [
  { label: 'ブラック', value: 'black', color: '#0a0a0a' },
  { label: 'オレンジ', value: 'orange', color: '#e37d00' },
  { label: 'イエロー', value: 'yellow', color: '#debd00' },
  { label: 'コルク', value: 'kurk', color: '#CE7220' },
  { label: 'ピンク', value: 'pink', color: '#ff1493' },
  { label: 'スカイブルー', value: 'skyBlue', color: '#4EB5DC' },
  { label: 'Cブラウン', value: 'cBrown', color: '#baa95b' },
  { label: 'レッド', value: 'red', color: '#c70000' },
  { label: 'ネイビー', value: 'navy', color: '#363C53' },
  { label: 'ブルー', value: 'blue', color: '#002bff' },
  { label: 'ホワイト', value: 'white', color: '#eee' },
  { label: 'グリーン', value: 'green', color: '#009c09' },
  { label: 'パープル', value: 'purple', color: '#6a1ca3' },
  { label: 'グレー', value: 'gray', color: '#828282' }
]
export const hamidashiObjs = [
  { label: '切りハミ(ホワイト)', value: 'kirihamiWhite', color: '#fff' },
  { label: '玉ハミ(イエロー)', value: 'yellow', color: '#E8C84B' },
  { label: '玉ハミ(レモンイエロー)', value: 'lemonYellow', color: '#D9D943' },
  { label: '玉ハミ(コルク)', value: 'cork', color: '#CE7220' },
  { label: '玉ハミ(チョコレート)', value: 'chocolate', color: '#70352e' },
  { label: '玉ハミ(レッド)', value: 'red', color: '#CB1527' },
  { label: '玉ハミ(ネイビー)', value: 'navy', color: '#202742' },
  { label: '玉ハミ(ブルー)', value: 'blue', color: '#193B85' },
  { label: '玉ハミ(ブラック)', value: 'black', color: '#1E1E1E' },
  { label: '玉ハミ(オレンジ)', value: 'orange', color: '#FF6C23' },
  { label: '玉ハミ(レッドオレンジ)', value: 'redOrange', color: '#FF362E' },
  { label: '玉ハミ(ベイラーブルー)', value: 'valorBlue', color: '#61BCBA' },
  { label: '玉ハミ(マディグラス)', value: 'mardiGras', color: '#4e734c' },
  { label: '玉ハミ(ティール)', value: 'teal', color: '#317290' },
  { label: '玉ハミ(パープル)', value: 'purple', color: '#664785' },
  { label: '玉ハミ(ピンク)', value: 'pink', color: '#F94C97' },
  { label: '玉ハミ(ホワイト)', value: 'white', color: '#fff' },
  { label: '玉ハミ(グレー)', value: 'gray', color: '#8A8C8C' },
  { label: '玉ハミ(Cブラウン)', value: 'brown', color: '#D7CEA5' }
]
export const hamidashiAllObjs = [{ label: 'ハミダシなし(ツマミ)', value: 'none', color: '#fff' }, ...hamidashiObjs]
export const hamidashiForCatcherObjs = hamidashiAllObjs
export const listLiningsMaterialObjs = [
  { label: 'ムートン(ホワイト)', value: 'moutonWhite', color: '#fff', imageUrl: '/atoms/colorSettings/mouton/white.jpg' },
  { label: 'ムートン(ブラック)', value: 'moutonBlack', color: '#1a1a1a', imageUrl: '/atoms/colorSettings/mouton/black.jpg' }
]
const commonPartsObjs = [
  { label: '全体', value: 'all', order: 0 },
  { label: '捕球面', value: 'catchFace', order: 1 },
  { label: 'ウェブ', value: 'web', order: 2 },
  { label: 'ヘリ革', value: 'edge', order: 4 },
  { label: '親指掛け紐', value: 'thumbHook', order: 5 },
  { label: '小指掛け紐', value: 'littleHook', order: 6 },
  { label: '指カバー/指当て', value: 'indexCover', order: 7 },
  { label: '裏革', value: 'linings', order: 8 }
]
const backBeltStyleGloveNoBeltObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2', value: 'thumbWeb', order: 10 },
  { label: '人差指3', value: 'indexWeb', order: 11 },
  { label: '人差指4', value: 'indexMiddle', order: 12 },
  { label: '中指5', value: 'middleIndex', order: 13 },
  { label: '中指6', value: 'middleRing', order: 14 },
  { label: '薬指7', value: 'ringMiddle', order: 15 },
  { label: '薬指8', value: 'ringLittle', order: 16 },
  { label: '小指9', value: 'littleRing', order: 17 },
  { label: '小指10', value: 'littleOut', order: 18 }
]
const backBeltStyleGloveObjs = [...backBeltStyleGloveNoBeltObjs, { label: 'バンド', value: 'listBelt', order: 19 }]
const backCrownBackGloveObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2', value: 'thumbWeb', order: 10 },
  { label: '人差指3', value: 'indexWeb', order: 11 },
  { label: '人差指4・中指5', value: 'indexLeftMiddleRight', order: 12 },
  { label: '中指6・薬指7', value: 'middleLeftRingRight', order: 13 },
  { label: '薬指8・小指9', value: 'ringLeftLittleRight', order: 14 },
  { label: '小指10', value: 'littleOut', order: 15 },
  { label: 'バンド', value: 'listBelt', order: 16 }
]
const backFirstBackGloveObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2・人差指・中指5', value: 'thumbIndexMiddle', order: 10 },
  { label: '中指6・薬指7', value: 'middleLeftRingRight', order: 11 },
  { label: '薬指8・小指9', value: 'ringLeftLittleRight', order: 12 },
  { label: '小指10', value: 'littleOut', order: 13 },
  { label: 'ベルト', value: 'listBelt', order: 14 }
]
const backGloveUsBackObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2', value: 'thumbWeb', order: 10 },
  { label: '親指根本', value: 'thumbRoot', order: 11 },
  { label: '人差指3', value: 'indexWeb', order: 12 },
  { label: '人差指4', value: 'indexMiddle', order: 13 },
  { label: '中指5', value: 'middleIndex', order: 14 },
  { label: '中指6', value: 'middleRing', order: 15 },
  { label: '薬指7', value: 'ringMiddle', order: 16 },
  { label: '薬指8', value: 'ringLittle', order: 17 },
  { label: '小指9', value: 'littleRing', order: 18 },
  { label: '小指10', value: 'littleOut', order: 19 }
]
const backGloveForUBackObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2・人差指3', value: 'thumbWebAndIndexWeb', order: 10 },
  { label: '人差指4', value: 'indexMiddle', order: 11 },
  { label: '中指5', value: 'middleIndex', order: 12 },
  { label: '中指6', value: 'middleRing', order: 13 },
  { label: '薬指7', value: 'ringMiddle', order: 14 },
  { label: '薬指8', value: 'ringLittle', order: 15 },
  { label: '小指9', value: 'littleRing', order: 16 },
  { label: '小指10', value: 'littleOut', order: 17 },
  { label: 'バンド', value: 'listBelt', order: 18 }
]
const backOfRegularBackStyleCatcherMittObjs = [
  { label: '親指・人差指・中指5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: '中指6・薬指', value: 'middleLeftRing', order: 10 },
  { label: '指出し', value: 'indexFingerTipOut', order: 11 },
  { label: '小指10', value: 'littleOut', order: 12 },
  { label: '土台', value: 'fingerStand', order: 13 },
  { label: 'バンド', value: 'listBelt', order: 14 }
]
const backOfBeltBackStyleCatcherMittObjs = [
  { label: '親指・人差指・中指5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: '中指6・薬指・小指', value: 'middleLeftRingLittle', order: 10 },
  { label: '土台', value: 'fingerStand' }
]
const backFirstMittObjs = [
  { label: 'バンド', value: 'listBelt', order: 9 },
  { label: '親指', value: 'thumb', order: 10 },
  { label: 'ウェブ下', value: 'underWeb', order: 11 },
  { label: 'ブーメラン', value: 'boomerang', order: 12 }
]
const backFirstMittNoBeltObjs = [
  { label: '親指', value: 'thumb', order: 9 },
  { label: 'ウェブ下', value: 'underWeb', order: 10 },
  { label: 'ブーメラン', value: 'boomerang', order: 11 }
]
export const catcherMittBackPartsObjs = [
  { label: '親指・人差指・中指5', value: 'thumbIndexMiddleRight', order: 12 },
  { label: '中指6・薬指・小指', value: 'middleLeftRingLittle', order: 13 },
  { label: '中指6・薬指', value: 'middleLeftRing', order: 14 },
  { label: '小指10', value: 'littleOut', order: 15 },
  { label: '土台', value: 'fingerStand', order: 16 }
]

// 捕球面
export const catchFacePartsObjs = commonPartsObjs
export const catcherMittCatchFaceObjs = [...commonPartsObjs, { label: 'ターゲット', value: 'catchFaceTarget', order: 9 }]
// 背面
export const gloveBeltBackPartsObjs = [...commonPartsObjs, ...backBeltStyleGloveObjs]
export const gloveBeltBackPartsNoBeltObjs = [...commonPartsObjs, ...backBeltStyleGloveNoBeltObjs]
export const gloveCrownBackPartsObjs = [...commonPartsObjs, ...backCrownBackGloveObjs]
export const gloveFirstBackPartsObjs = [...commonPartsObjs, ...backFirstBackGloveObjs]
export const gloveUsBackPartsObjs = [...commonPartsObjs, ...backGloveUsBackObjs]
export const infielderBackPartsForUBackObjs = [...commonPartsObjs, ...backGloveForUBackObjs]
export const firstMittBackPartsObjs = [...commonPartsObjs, ...backFirstMittObjs]
export const firstMittBackPartsNoBeltObjs = [...commonPartsObjs, ...backFirstMittNoBeltObjs]
export const catcherMittBackofRegularBackStylePartsObjs = [...commonPartsObjs, ...backOfRegularBackStyleCatcherMittObjs]
export const catcherMittBackofBeltBackStylePartsObjs = [...commonPartsObjs, ...backOfBeltBackStyleCatcherMittObjs]
export const partsObjs = [
  ...commonPartsObjs,
  ...catchFacePartsObjs,
  ...backBeltStyleGloveObjs,
  ...backCrownBackGloveObjs,
  ...backFirstBackGloveObjs,
  ...firstMittBackPartsObjs,
  ...catcherMittCatchFaceObjs,
  ...backOfBeltBackStyleCatcherMittObjs,
  ...backOfRegularBackStyleCatcherMittObjs
]
export const atomsLabelObjs = [
  { label: '黒xゴールドxゴールド', value: 'ccc', imageUrl: '/atoms/labels/c.jpg' },
  { label: '黒xゴールドxシャンパンゴールド', value: 'ddd', imageUrl: '/atoms/labels/d.jpg' },
  { label: 'ネイビーx赤xゴールド', value: 'eee', imageUrl: '/atoms/labels/e.jpg' },
  { label: '赤xネイビーxゴールド', value: 'fff', imageUrl: '/atoms/labels/f.jpg' },
  { label: '黒xゴールドxシルバー', value: 'ggg', imageUrl: '/atoms/labels/g.jpg' },
  { label: 'ブルーx赤xゴールド', value: 'hhh', imageUrl: '/atoms/labels/h.jpg' },
  { label: '黒xゴールドxブルー', value: 'iii', imageUrl: '/atoms/labels/i.jpg' },
  { label: '黒xプラチナゴールドxゴールド', value: 'jjj', imageUrl: '/atoms/labels/j.jpg' },
  { label: '黒x黒x黒', value: 'kkk', imageUrl: '/atoms/labels/k.jpg' },
  { label: '黒xシリコンゴールド', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '黒xシリコンプラチナ', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '黒xシリコンブラックラメ', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '黒xゴールドxピンク', value: 'uuu', imageUrl: '/atoms/labels/u.jpg' },
  { label: '黒xライトゴールドxライトゴールド', value: 'yyy', imageUrl: '/atoms/labels/y.jpg' },
  { label: '黒xシリコンシルバー', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const atomsERMLabelObjs = [
  { label: '黒xシリコンゴールド', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '黒xシリコンプラチナ', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '黒xシリコンブラックラメ', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '黒xシリコンシルバー', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const moutonObjs = [
  { label: '白', value: 'white', color: '#fff' },
  { label: '黒', value: 'black', color: '#1E1E1E' }
]
export const dispatchPremiumSteerObjectsJa = {
  linings: [...premiumLeatherColorObjsJa],
  all: premiumLeatherColorObjsJa,
  catchFace: premiumLeatherColorObjsJa,
  web: premiumLeatherColorObjsJa,
  web2: premiumLeatherColorObjsJa,
  edge: leatherColorObjsJa,
  thumbHook: premiumLeatherColorObjsJa,
  thumbWebAndIndexWeb: premiumLeatherColorObjsJa,
  littleHook: premiumLeatherColorObjsJa,
  indexCover: premiumLeatherColorObjsJa,
  thumbOut: premiumLeatherColorObjsJa,
  thumbWeb: premiumLeatherColorObjsJa,
  thumbRoot: premiumLeatherColorObjsJa,
  thumbIndexMiddle: premiumLeatherColorObjsJa,
  thumbIndexMiddleRight: premiumLeatherColorObjsJa,
  indexWeb: premiumLeatherColorObjsJa,
  indexMiddle: premiumLeatherColorObjsJa,
  middleIndex: premiumLeatherColorObjsJa,
  middleRing: premiumLeatherColorObjsJa,
  middleLeftRing: premiumLeatherColorObjsJa,
  middleLeftRingRight: premiumLeatherColorObjsJa,
  middleLeftRingLittle: premiumLeatherColorObjsJa,
  ringMiddle: premiumLeatherColorObjsJa,
  ringLittle: premiumLeatherColorObjsJa,
  ringLeftLittleRight: premiumLeatherColorObjsJa,
  littleRing: premiumLeatherColorObjsJa,
  littleOut: premiumLeatherColorObjsJa,
  listBelt: premiumLeatherColorObjsJa,
  thumb: premiumLeatherColorObjsJa,
  boomerang: premiumLeatherColorObjsJa,
  underWeb: premiumLeatherColorObjsJa,
  catchFaceTarget: premiumLeatherColorObjsJa,
  indexLeftMiddleRight: premiumLeatherColorObjsJa,
  indexAndMiddle: premiumLeatherColorObjsJa,
  fingerStand: premiumLeatherColorObjsJa,
  indexFingerTipOut: premiumLeatherColorObjsJa
}
export const dispatchNorthSteerObjectsJa = {
  linings: [...northLeatherColorObjsJa],
  all: northLeatherColorObjsJa,
  catchFace: northLeatherColorObjsJa,
  web: northLeatherColorObjsJa,
  web2: northLeatherColorObjsJa,
  edge: leatherColorObjsJa,
  thumbHook: northLeatherColorObjsJa,
  thumbWebAndIndexWeb: northLeatherColorObjsJa,
  littleHook: northLeatherColorObjsJa,
  indexCover: northLeatherColorObjsJa,
  thumbOut: northLeatherColorObjsJa,
  thumbWeb: northLeatherColorObjsJa,
  thumbRoot: northLeatherColorObjsJa,
  thumbIndexMiddle: northLeatherColorObjsJa,
  thumbIndexMiddleRight: northLeatherColorObjsJa,
  indexWeb: northLeatherColorObjsJa,
  indexMiddle: northLeatherColorObjsJa,
  middleIndex: northLeatherColorObjsJa,
  middleRing: northLeatherColorObjsJa,
  middleLeftRing: northLeatherColorObjsJa,
  middleLeftRingRight: northLeatherColorObjsJa,
  middleLeftRingLittle: northLeatherColorObjsJa,
  ringMiddle: northLeatherColorObjsJa,
  ringLittle: northLeatherColorObjsJa,
  ringLeftLittleRight: northLeatherColorObjsJa,
  littleRing: northLeatherColorObjsJa,
  littleOut: northLeatherColorObjsJa,
  listBelt: northLeatherColorObjsJa,
  thumb: northLeatherColorObjsJa,
  boomerang: northLeatherColorObjsJa,
  underWeb: northLeatherColorObjsJa,
  catchFaceTarget: northLeatherColorObjsJa,
  indexLeftMiddleRight: northLeatherColorObjsJa,
  indexAndMiddle: northLeatherColorObjsJa,
  fingerStand: northLeatherColorObjsJa,
  indexFingerTipOut: northLeatherColorObjsJa
}
export const dispatchSouthSteerObjectsJa = {
  linings: [...southLeatherColorObjsJa],
  all: southLeatherColorObjsJa,
  catchFace: southLeatherColorObjsJa,
  web: southLeatherColorObjsJa,
  web2: southLeatherColorObjsJa,
  edge: southLeatherColorObjsJa,
  thumbHook: southLeatherColorObjsJa,
  thumbWebAndIndexWeb: southLeatherColorObjsJa,
  littleHook: southLeatherColorObjsJa,
  indexCover: southLeatherColorObjsJa,
  thumbOut: southLeatherColorObjsJa,
  thumbWeb: southLeatherColorObjsJa,
  thumbRoot: southLeatherColorObjsJa,
  thumbIndexMiddle: southLeatherColorObjsJa,
  thumbIndexMiddleRight: southLeatherColorObjsJa,
  indexWeb: southLeatherColorObjsJa,
  indexMiddle: southLeatherColorObjsJa,
  middleIndex: southLeatherColorObjsJa,
  middleRing: southLeatherColorObjsJa,
  middleLeftRing: southLeatherColorObjsJa,
  middleLeftRingRight: southLeatherColorObjsJa,
  middleLeftRingLittle: southLeatherColorObjsJa,
  ringMiddle: southLeatherColorObjsJa,
  ringLittle: southLeatherColorObjsJa,
  ringLeftLittleRight: southLeatherColorObjsJa,
  littleRing: southLeatherColorObjsJa,
  littleOut: southLeatherColorObjsJa,
  listBelt: southLeatherColorObjsJa,
  thumb: southLeatherColorObjsJa,
  boomerang: southLeatherColorObjsJa,
  underWeb: southLeatherColorObjsJa,
  catchFaceTarget: southLeatherColorObjsJa,
  indexLeftMiddleRight: southLeatherColorObjsJa,
  indexAndMiddle: southLeatherColorObjsJa,
  fingerStand: southLeatherColorObjsJa,
  indexFingerTipOut: southLeatherColorObjsJa
}
const deerBlack = { label: 'ディア調ブラック', value: 'deerBlack', color: '#1E1E1E' }
export const dispatchNineStatesObjectsJa = {
  linings: [...nineStatesLeatherColorObjsJa, deerBlack],
  all: nineStatesLeatherColorObjsJa,
  catchFace: nineStatesLeatherColorObjsJa,
  web: nineStatesLeatherColorObjsJa,
  web2: nineStatesLeatherColorObjsJa,
  edge: leatherColorObjsJa,
  thumbHook: nineStatesLeatherColorObjsJa,
  thumbWebAndIndexWeb: nineStatesLeatherColorObjsJa,
  littleHook: nineStatesLeatherColorObjsJa,
  indexCover: nineStatesLeatherColorObjsJa,
  thumbOut: nineStatesLeatherColorObjsJa,
  thumbWeb: nineStatesLeatherColorObjsJa,
  thumbRoot: nineStatesLeatherColorObjsJa,
  thumbIndexMiddle: nineStatesLeatherColorObjsJa,
  thumbIndexMiddleRight: nineStatesLeatherColorObjsJa,
  indexWeb: nineStatesLeatherColorObjsJa,
  indexMiddle: nineStatesLeatherColorObjsJa,
  middleIndex: nineStatesLeatherColorObjsJa,
  middleRing: nineStatesLeatherColorObjsJa,
  middleLeftRing: nineStatesLeatherColorObjsJa,
  middleLeftRingRight: nineStatesLeatherColorObjsJa,
  middleLeftRingLittle: nineStatesLeatherColorObjsJa,
  ringMiddle: nineStatesLeatherColorObjsJa,
  ringLittle: nineStatesLeatherColorObjsJa,
  ringLeftLittleRight: nineStatesLeatherColorObjsJa,
  littleRing: nineStatesLeatherColorObjsJa,
  littleOut: nineStatesLeatherColorObjsJa,
  listBelt: nineStatesLeatherColorObjsJa,
  thumb: nineStatesLeatherColorObjsJa,
  boomerang: nineStatesLeatherColorObjsJa,
  underWeb: nineStatesLeatherColorObjsJa,
  catchFaceTarget: nineStatesLeatherColorObjsJa,
  indexLeftMiddleRight: nineStatesLeatherColorObjsJa,
  indexAndMiddle: nineStatesLeatherColorObjsJa,
  fingerStand: nineStatesLeatherColorObjsJa,
  indexFingerTipOut: nineStatesLeatherColorObjsJa
}
export const dispatchObjects = {
  linings: liningColorObjs,
  listLiningsMaterial: listLiningsMaterialObjs,
  all: leatherColorObjsJa,
  catchFace: leatherColorObjsJa,
  web: leatherColorObjsJa,
  web2: leatherColorObjsJa,
  edge: leatherColorObjsJa,
  thumbHook: leatherColorObjsJa,
  thumbWebAndIndexWeb: leatherColorObjsJa,
  littleHook: leatherColorObjsJa,
  indexCover: leatherColorObjsJa,
  thumbOut: leatherColorObjsJa,
  thumbWeb: leatherColorObjsJa,
  thumbRoot: leatherColorObjsJa,
  thumbIndexMiddle: leatherColorObjsJa,
  thumbIndexMiddleRight: leatherColorObjsJa,
  indexWeb: leatherColorObjsJa,
  indexMiddle: leatherColorObjsJa,
  middleIndex: leatherColorObjsJa,
  middleRing: leatherColorObjsJa,
  middleLeftRing: leatherColorObjsJa,
  middleLeftRingRight: leatherColorObjsJa,
  middleLeftRingLittle: leatherColorObjsJa,
  ringMiddle: leatherColorObjsJa,
  ringLittle: leatherColorObjsJa,
  ringLeftLittleRight: leatherColorObjsJa,
  littleRing: leatherColorObjsJa,
  littleOut: leatherColorObjsJa,
  listBelt: leatherColorObjsJa,
  thumb: leatherColorObjsJa,
  boomerang: leatherColorObjsJa,
  underWeb: leatherColorObjsJa,
  catchFaceTarget: leatherColorObjsJa,
  indexLeftMiddleRight: leatherColorObjsJa,
  indexAndMiddle: leatherColorObjsJa,
  fingerStand: leatherColorObjsJa,
  indexFingerTipOut: leatherColorObjsJa
}

export const PARTS_BUTTON_OPTION = 'atoms-parts-button-option'
export const LEATHER_COLOR_BUTTON_OPTION = 'atoms-leather-color-button-option'
export const LACE_COLOR_BUTTON_OPTION = 'atoms-lace-color-button-option'
export const EDGE_COLOR_BUTTON_OPTION = 'atoms-edge-color-button-option'
export const STITCH_COLOR_BUTTON_OPTION = 'atoms-stitch-color-button-option'
export const HAMIDASHI_COLOR_BUTTON_OPTION = 'atoms-hamidashi-color-button-option'
export const MOUTON_BUTTON_OPTION = 'atoms-mouton-button-option'
export const ATOMS_LABEL_BUTTON_OPTION = 'atoms-label-button-option'
