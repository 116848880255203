import { unselectedColorState, unselectedColorStateEn, unselectedColorStateKo, unselectedState } from 'src/features/brands/reducer'

// 全ポジションで使うカラー情報
export const colorInitialState = {
  parts: { label: '全体', value: 'all' },
  all: unselectedColorState,
  catchFace: unselectedColorState,
  edge: unselectedColorState,
  lace: unselectedColorState,
  linings: unselectedColorState,
  hamidashi: unselectedColorState,
  stitch: unselectedColorState,
  web: unselectedColorState,
  web2: unselectedColorState,
  thumbHook: unselectedColorState,
  littleHook: unselectedColorState,
  listBelt: unselectedColorState,
  indexCover: unselectedColorState,
  mouton: unselectedColorState,
  atomsLabel: unselectedState
}

// 投・内・外で使うカラー情報
export const gloveColorInitialState = {
  thumbOut: unselectedColorState,
  thumbWeb: unselectedColorState,
  thumbRoot: unselectedColorState,
  thumbWebAndIndexWeb: unselectedColorState,
  thumbIndexMiddle: unselectedColorState,
  thumbIndexMiddleRight: unselectedColorState,
  indexWeb: unselectedColorState,
  indexMiddle: unselectedColorState,
  indexLeftMiddleRight: unselectedColorState,
  middleIndex: unselectedColorState,
  middleLeftRingRight: unselectedColorState,
  middleRing: unselectedColorState,
  ringMiddle: unselectedColorState,
  ringLeftLittleRight: unselectedColorState,
  ringLittle: unselectedColorState,
  littleRing: unselectedColorState,
  littleOut: unselectedColorState
}
export const gloveColorInitialStateEn = {
  thumbOut: unselectedColorStateEn,
  thumbWeb: unselectedColorStateEn,
  thumbRoot: unselectedColorStateEn,
  thumbWebAndIndexWeb: unselectedColorStateEn,
  thumbIndexMiddle: unselectedColorStateEn,
  indexWeb: unselectedColorStateEn,
  indexMiddle: unselectedColorStateEn,
  indexLeftMiddleRight: unselectedColorStateEn,
  middleIndex: unselectedColorStateEn,
  middleLeftRingRight: unselectedColorStateEn,
  middleRing: unselectedColorStateEn,
  ringMiddle: unselectedColorStateEn,
  ringLeftLittleRight: unselectedColorStateEn,
  ringLittle: unselectedColorStateEn,
  littleRing: unselectedColorStateEn,
  littleOut: unselectedColorStateEn
}
export const gloveColorInitialStateKo = {
  thumbOut: unselectedColorStateKo,
  thumbWeb: unselectedColorStateKo,
  thumbRoot: unselectedColorStateKo,
  thumbWebAndIndexWeb: unselectedColorStateKo,
  thumbIndexMiddle: unselectedColorStateKo,
  indexWeb: unselectedColorStateKo,
  indexMiddle: unselectedColorStateKo,
  indexLeftMiddleRight: unselectedColorStateKo,
  middleIndex: unselectedColorStateKo,
  middleLeftRingRight: unselectedColorStateKo,
  middleRing: unselectedColorStateKo,
  ringMiddle: unselectedColorStateKo,
  ringLeftLittleRight: unselectedColorStateKo,
  ringLittle: unselectedColorStateKo,
  littleRing: unselectedColorStateKo,
  littleOut: unselectedColorStateKo
}

export const initialEmbroideryState = {
  id: 0,
  typeFace: { label: '', value: '' },
  position: { label: '', value: '' },
  color: { label: '未選択', value: 'unselected', color: '' },
  shadowColor: { label: '未選択', value: 'unselected' },
  edgeColor: { label: '未選択', value: 'unselected' },
  content: ''
}
