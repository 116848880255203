import { BaseInitialState } from 'src/types'

export const unselectedState = { label: '未選択', value: 'unselected' }
export const unselectedStateWithPrice = { label: '未選択', value: 'unselected', price: 0 }
export const unselectedColorState = { label: '未選択', value: 'unselected', color: '#fff' }
export const unselectedStateEn = { label: 'Unselected', value: 'unselected' }
export const unselectedColorStateEn = { label: 'Unselected', value: 'unselected', color: '#fff' }
export const unselectedStateKo = { label: '미선택', value: 'unselected' }
export const unselectedColorStateKo = { label: '미선택', value: 'unselected', color: '#fff' }

export const baseInitialState: BaseInitialState = {
  // トップページのloading
  isLoading: false,
  backStyle: unselectedState,
  dominantArm: unselectedState,
  size: unselectedState,
  fingerGuardType: unselectedState,
  baseModel: {
    productNumber: '',
    imageUrl: '',
    dominantArm: 'unselected',
    position: '',
    size: 0,
    webParts: '',
    description: '',
    series: '',
    backStyle: '',
    imageWidth: 0,
    imageHeight: 0
  }
}

export const baseInitialStateEn: BaseInitialState = {
  // トップページのloading
  isLoading: false,
  backStyle: unselectedStateEn,
  dominantArm: unselectedStateEn,
  size: unselectedStateEn,
  fingerGuardType: unselectedStateEn,
  baseModel: {
    productNumber: '',
    imageUrl: '',
    dominantArm: 'unselected',
    position: 'infielder', // 本当はここ、infielderでありたくない
    size: 0,
    webParts: '',
    description: '',
    series: '',
    backStyle: '',
    imageWidth: 0,
    imageHeight: 0
  }
}

export const baseInitialStateKo: BaseInitialState = {
  // トップページのloading
  isLoading: false,
  backStyle: unselectedStateKo,
  dominantArm: unselectedStateKo,
  size: unselectedStateKo,
  fingerGuardType: unselectedStateKo,
  baseModel: {
    productNumber: '',
    imageUrl: '',
    dominantArm: '미선택',
    position: 'infielder', // 本当はここ、infielderでありたくない
    size: 0,
    webParts: '',
    description: '',
    series: '',
    backStyle: '',
    imageWidth: 0,
    imageHeight: 0
  }
}

export const colorInitialState = {
  parts: { label: '全体', value: 'all' },
  all: unselectedColorState,
  catchFace: unselectedColorState,
  edge: unselectedColorState,
  lace: unselectedColorState,
  linings: unselectedColorState,
  hamidashi: unselectedColorState,
  listLiningsMaterial: unselectedColorState,
  stitch: { label: '未選択', value: 'unselected', color: '#eee' },
  web: unselectedColorState,
  thumbHook: unselectedColorState,
  littleHook: unselectedColorState,
  listBelt: unselectedColorState,
  indexCover: unselectedColorState
}

export const colorInitialStateEn = {
  parts: { label: 'Over All', value: 'all' },
  all: unselectedColorStateEn,
  catchFace: unselectedColorStateEn,
  edge: unselectedColorStateEn,
  lace: unselectedColorStateEn,
  linings: unselectedColorStateEn,
  hamidashi: unselectedColorStateEn,
  listLiningsMaterial: unselectedColorStateEn,
  stitch: { label: 'Unselected', value: 'unselected', color: '#eee' },
  web: unselectedColorStateEn,
  thumbHook: unselectedColorStateEn,
  littleHook: unselectedColorStateEn,
  listBelt: unselectedColorStateEn,
  indexCover: unselectedColorStateEn
}

export const colorInitialStateKo = {
  parts: { label: '전체', value: 'all' },
  all: unselectedColorStateKo,
  catchFace: unselectedColorStateKo,
  edge: unselectedColorStateKo,
  lace: unselectedColorStateKo,
  linings: unselectedColorStateKo,
  hamidashi: unselectedColorStateKo,
  listLiningsMaterial: unselectedColorStateKo,
  stitch: { label: '미선택', value: 'unselected', color: '#eee' },
  web: unselectedColorStateKo,
  thumbHook: unselectedColorStateKo,
  littleHook: unselectedColorStateKo,
  listBelt: unselectedColorStateKo,
  indexCover: unselectedColorStateKo
}

// 投・内・外で使うカラー情報
export const gloveColorInitialState = {
  thumbOut: unselectedColorState,
  thumbWeb: unselectedColorState,
  thumbRoot: unselectedColorState,
  indexWeb: unselectedColorState,
  indexMiddle: unselectedColorState,
  middleIndex: unselectedColorState,
  middleRing: unselectedColorState,
  ringMiddle: unselectedColorState,
  ringLittle: unselectedColorState,
  littleRing: unselectedColorState,
  littleOut: unselectedColorState
}

export const initialEmbroideryState = {
  id: 0,
  typeFace: { label: '', value: '' },
  position: { label: '', value: '' },
  color: { label: '未選択', value: 'unselected', color: '' },
  shadowColor: { label: '未選択', value: 'unselected' },
  edgeColor: { label: '未選択', value: 'unselected' },
  content: ''
}

export const initialPersonalState = {
  userName: '',
  userNameKana: '',
  phoneNumber: '',
  mailAddress: '',
  league: '',
  position: '',
  remarks: ''
}
