export const leagueEnOptions = [
  { label: 'in USA', value: 'usa' },
  { label: 'in CHINA', value: 'china' },
  { label: 'in JAPAN', value: 'japan' }
]
export const leagueKoOptions = [
  { label: '프로야구', value: 'プロ野球' },
  { label: '사회인야구', value: '社会人野球' },
  { label: '대학야구', value: '大学野球' },
  { label: '고교야구', value: '高校野球' },
  { label: '취미', value: '草野球' }
]

export const leagueOptions = [
  { label: 'プロ野球(NPB,独立L)', value: 'professional' },
  { label: '社会人野球', value: 'inCorporate' },
  { label: '大学野球', value: 'collegeLeague' },
  { label: '高校野球', value: 'highSchool' },
  { label: 'ボーイズリーグ', value: 'boysLeague' },
  { label: 'シニアリーグ', value: ' seniorLeague' },
  { label: 'ヤングリーグ', value: 'youngLeague' },
  { label: 'リトルリーグ', value: '' },
  { label: '草野球', value: 'hobby' }
]

export const positionJaOptions = [
  { label: '投手用', value: 'pitcher', attribute: 'pitcher' },
  { label: '捕手用', value: 'catcher', attribute: 'catcher' },
  { label: '一塁手', value: 'firstBaseman', attribute: 'first' },
  { label: '二塁手', value: 'secondBaseman', attribute: 'infielder' },
  { label: '三塁手', value: 'thirdBaseman', attribute: 'infielder' },
  { label: '遊撃手', value: 'shortStop', attribute: 'infielder' },
  { label: '左翼手', value: 'leftfielder', attribute: 'outfielder' },
  { label: '中堅手', value: 'centerfielder', attribute: 'outfielder' },
  { label: '右翼手', value: 'rightfielder', attribute: 'outfielder' }
]
export const positionEnOptions = [
  { label: 'Pitcher', value: 'pitcher', attribute: 'pitcher' },
  { label: 'Catcher', value: 'catcher', attribute: 'catcher' },
  { label: 'First Basenan', value: 'firstBaseman', attribute: 'first' },
  { label: 'Second Baseman', value: 'secondBaseman', attribute: 'infielder' },
  { label: 'Third Baseman', value: 'thirdBaseman', attribute: 'infielder' },
  { label: 'Short Stop', value: 'shortStop', attribute: 'infielder' },
  { label: 'Left Fielder', value: 'leftfielder', attribute: 'outfielder' },
  { label: 'Center Fielder', value: 'centerfielder', attribute: 'outfielder' },
  { label: 'Right Fielder', value: 'rightfielder', attribute: 'outfielder' }
]
export const positionKoOptions = [
  { label: '투수', value: 'pitcher', attribute: 'pitcher' },
  { label: '포수', value: 'catcher', attribute: 'catcher' },
  { label: '1루수', value: 'firstBaseman', attribute: 'first' },
  { label: '2루수', value: 'secondBaseman', attribute: 'infielder' },
  { label: '3루수', value: 'thirdBaseman', attribute: 'infielder' },
  { label: '유격수', value: 'shortStop', attribute: 'infielder' },
  { label: '좌익수', value: 'leftFielder', attribute: 'outfielder' },
  { label: '중견수', value: 'centerFielder', attribute: 'outfielder' },
  { label: '우익수', value: 'rightFielder', attribute: 'outfielder' }
]

export const positionTranslate = [
  { korea: '물주전자', japanese: '投手' }, // OLD
  { korea: '투수', japanese: '投手' },
  { korea: '포수', japanese: '捕手' },
  { korea: '1루수', japanese: '一塁手' },
  { korea: '2루수', japanese: '二塁手' },
  { korea: '3루수', japanese: '三塁手' },
  { korea: '유격수', japanese: '遊撃手' },
  { korea: '좌익수', japanese: '左翼手' },
  { korea: '중견수', japanese: '中堅手' },
  { korea: '우익수', japanese: '右翼手' }
]
