export const hardOption = { label: '硬式用', value: 'hard' }
export const japaneseSoftOption = { label: '軟式用', value: 'soft' }
export const kindsObjs = [hardOption, japaneseSoftOption]

export const dominantArmObjs = [
  { label: '右投げ', value: 'rightThrow' },
  { label: '左投げ', value: 'leftThrow' }
]
export const onlyRightThrowObjs = [{ label: '右投げ', value: 'rightThrow' }]

export const sizeObjs = [
  { label: '10mm大きく', value: 'large10', price: 3300 },
  { label: '5mm大きく', value: 'large5', price: 3300 },
  { label: '基本モデルズバリ', value: 'default', price: 0 },
  { label: '5mm小さく', value: 'small5', price: 3300 },
  { label: '10mm小さく', value: 'small10', price: 3300 }
]

export const backLaceObjs = [
  { label: '有り', value: 'straight', imageUrl: '/atoms/baseSettings/backLace/exist.jpg' },
  { label: '無し', value: 'none', imageUrl: '/atoms/baseSettings/backLace/none.jpg' }
]
export const backStyleObjs = [
  { label: 'ベルト', value: 'beltBackStyle' },
  { label: 'ファーストバック', value: 'firstBackStyle' },
  { label: 'ウィングチップ', value: 'crownBackStyle' },
  { label: 'レギュラーバック', value: 'regularBackStyle' }
]
export const backStyleForPitcherObjs = [
  { label: 'ベルト', value: 'beltBackStyle', imageUrl: '/atoms/baseSettings/backStyle/glove/regular.jpg' },
  { label: 'ファーストバック', value: 'firstBackStyle', imageUrl: '/atoms/baseSettings/backStyle/glove/firstBack.jpg' },
  { label: 'ウィングチップ', value: 'crownBackStyle', imageUrl: '/atoms/baseSettings/backStyle/glove/crownBack.jpg' }
]

export const backStyleForCatcherMittObjs = [
  { label: 'ベルト', value: 'beltBackStyle', imageUrl: '/atoms/baseSettings/backStyle/catcherMitt/belt.jpg' },
  { label: 'レギュラーバック', value: 'regularBackStyle', imageUrl: '/atoms/baseSettings/backStyle/catcherMitt/regular.jpg' }
]

export const fingerGuardTypeAllObjects = [
  { label: '指カバー(人差指)', value: 'indexCover', imageUrl: '/atoms/baseSettings/fingerGuardType/indexCover.jpg' },
  { label: '指カバー(中指)', value: 'middleCover', imageUrl: '/atoms/baseSettings/fingerGuardType/middleCover.jpg' },
  { label: '指アテ(人差指)', value: 'indexPad', imageUrl: '/atoms/baseSettings/fingerGuardType/indexPad.jpg' },
  { label: '指アテ(中指)', value: 'middlePad', imageUrl: '/atoms/baseSettings/fingerGuardType/middlePad.jpg' },
  { label: '無し', value: 'none', imageUrl: '/atoms/baseSettings/fingerGuardType/none.jpg' }
]
export const fingerGuardTypeObjs = [
  { label: '指カバー(人差指)', value: 'indexCover', imageUrl: '/atoms/baseSettings/fingerGuardType/indexCover.jpg' },
  { label: '指アテ(人差指)', value: 'indexPad', imageUrl: '/atoms/baseSettings/fingerGuardType/indexPad.jpg' },
  { label: '無し', value: 'none', imageUrl: '/atoms/baseSettings/fingerGuardType/none.jpg' }
]

export const fingerGuardForFirstMittObjs = [
  { label: '指アテ(人差指)', value: 'indexPad', imageUrl: '/atoms/baseSettings/fingerGuardType/indexPad.jpg' },
  { label: '無し', value: 'none', imageUrl: '/atoms/baseSettings/fingerGuardType/none.jpg' }
]

export const fingerGuardTypeForOutfielderObjs = fingerGuardTypeAllObjects

export const materialPackObjs = [
  { label: 'たてとじ', value: 'vertical', imageUrl: '/atoms/baseSettings/materialPack/vertical.jpg' },
  { label: 'よことじ', value: 'sideDouble', imageUrl: '/atoms/baseSettings/materialPack/sideDouble.jpg' }
]

export const moldingJaObjs = [
  { label: '型付け加工あり', value: 'moldingProcess', price: 8800 },
  { label: 'None', value: 'none', price: 0 }
]

export const loopOfRingFingerObjs = [
  { label: '有り', value: 'loopOfRingFinger', price: 3300, imageUrl: '/atoms/baseSettings/loopOfFinger/exist.jpg' },
  { label: '無し', value: 'none', price: 0, imageUrl: '/atoms/baseSettings/loopOfFinger/none.jpg' }
]
