// BASE SET
export const SET_BASE_MODEL = 'setBaseModel'
export const SET_TANNER = 'setTanner'
export const SET_DOMINANT_ARM = 'setDominantArm'
export const SET_SIZE = 'setSize'
export const SET_BACK_LACE = 'setBackLace'
export const SET_BACK_STYLE = 'setBackStyle'
export const SET_LININGS = 'setLinings'
export const SET_WEB_PARTS = 'setWebParts'
export const SET_ATOMS_LABEL = 'setAtomsLabel'
export const SET_MOLDIGN_ORDER = 'setMoldingOrder'
export const SET_FINGER_GUARD_TYPE = 'setFingerGuardType'
export const SET_MATERIAL_PACK = 'setMaterialPack'
// ragdelion original ここから
export const SET_MATERIAL_TYPE = 'setMaterialType'
export const SET_BANK_LACE_DIRECTION = 'setBankLaceDirection'
export const SET_LOOP_OF_RING_FINGER = 'setLoopOfRingFinger'
export const SET_LOOP_OF_RING_FINGER_COLOR = 'setLoopOfRingFingerColor'
export const SET_LABEL_POSITION = 'setLabelPosition'
export const SET_BELT_TYPE = 'setBeltType'
export const SET_LIST_LINING_MATERIAL = 'setListLiningMaterial'

// COLOR
export const SET_SELECTED_PARTS = 'setSelectedParts'
export const SET_ALL_LEATHER_COLOR = 'setAllLeatherColor'
export const SET_LEATHER_COLOR = 'setLeatherColor'
export const SET_LACE_COLOR = 'setLaceColor'
export const SET_EDGE_COLOR = 'setEdgeColor'
export const SET_TARGET_COLOR = 'setTargetColor'
export const SET_LININGS_COLOR = 'setLiningsColor'
export const SET_STITCH_COLOR = 'setStitchColor'
export const SET_LABEL = 'setLabel'
export const SET_WEB_COLOR = 'setWebColor'
export const SET_WEB_COLOR_2 = 'setWebColor2'
export const SET_MOUTON_COLOR = 'setMoutonColor'
export const SET_THUMB_HOOK = 'setThumbHook'
export const SET_LITTLE_HOOK = 'setLittleHook'
export const SET_LIST_BELT_COLOR = 'setListBeltColor'
export const SET_INDEX_COVER_COLOR = 'setIndexCoverColor'
export const SET_BAG_FOUNDATION_COLOR = 'setBagFoundationColor'
export const SET_RING_AND_LITTLE_COLOR = 'setRingAndLittleColor'
export const SET_MIDDLE_COLOR = 'setMiddleColor'
export const SET_INDEX_AND_THUMB_COLOR = 'setIndexAndThumbColor'
export const SET_SHELLARMOVE_COLOR = 'setShellarmoveColor'
export const SET_HAMIDASHI = 'setHamidashi'
export const SET_HAMIDASHI_OF_CATCH_FACE = 'setHamidashiOfCatchFace'

export const SET_THUMB_OUT_COLOR = 'thumbOut'
export const SET_THUMB_WEB_COLOR = 'thumbWeb'
export const SET_THUMB_ROOT_COLOR = 'thumbRoot'
export const SET_THUMB_WEB_AND_INDEX_WEB = 'thumbWebAndIndexWeb'
export const SET_THUMB_INDEX_MIDDLE = 'thumbIndexMiddle'
export const SET_THUMB_INDEX_MIDDLE_RIGHT = 'thumbIndexMiddleRight'
export const SET_MIDDLE_LEFT_RING_LITTLE = 'middleLeftRingLittle'
export const SET_MIDDLE_LEFT_RING = 'middleLeftRing'
export const SET_INDEX_WEB_COLOR = 'indexWeb'
export const SET_INDEX_MIDDLE_COLOR = 'indexMiddle'
export const SET_INDEX_LEFT_MIDDLE_RIGHT_COLOR = 'indexLeftMiddleRight'
export const SET_MIDDLE_INDEX_COLOR = 'middleIndex'
export const SET_MIDDLE_RING_COLOR = 'middleRing'
export const SET_MIDDLE_LEFT_RING_RIGHT_COLOR = 'middleLeftRingRight'
export const SET_RING_MIDDLE_COLOR = 'ringMiddle'
export const SET_RING_LITTLE_COLOR = 'ringLittle'
export const SET_RING_LEFT_LITTLE_RIGHT_COLOR = 'ringLeftLittleRight'

export const SET_LITTLE_RING_COLOR = 'littleRing'
export const SET_LITTLE_OUT_COLOR = 'littleOut'

// ファーストミット
export const SET_THUMB_COLOR = 'thumb'
export const SET_UNDER_WEB_COLOR = 'underWeb'
export const SET_BOOMERANG_COLOR = 'boomerang'

// キャッチャーミット
export const SET_THUMB_MACHI_COLOR = 'thumbMachi'
export const SET_LITTLE_MACHI_COLOR = 'littleMachi'
export const SET_CATCH_FACE_TARGET_COLOR = 'catchFaceTarget'
export const SET_INDEX_AND_MIDDLE_COLOR = 'indexAndMiddle'
export const SET_FINGER_STAND_COLOR = 'fingerStand'
export const SET_INDEX_FINGER_TIP_OUT_COLOR = 'indexFingerTipOut'

// EMBROIDERY
export const SET_EMBROIDERIES = 'setEmbroideries'
export const ADD_EMBROIDERY = 'addEmbroideries'
export const REMOVE_EMBROIDERY = 'RemoveEmbroideries'

// ALL
export const SET_PERSONAL = 'setPersonal'
export const SET_FIGURE_PANEL_NUMBER = 'setFigurePanelNumber'

// これはカラーだけに関するconstantsなので、別ファイルにあるべき
export const DISPATCHER = {
  tanner: SET_TANNER,
  dominantArm: SET_DOMINANT_ARM,
  backStyle: SET_BACK_STYLE,
  size: SET_SIZE,
  backLace: SET_BACK_LACE,
  fingerGuardType: SET_FINGER_GUARD_TYPE,
  materialPack: SET_MATERIAL_PACK,
  webParts: SET_WEB_PARTS,
  loopOfRingFinger: SET_LOOP_OF_RING_FINGER,
  materialType: SET_MATERIAL_TYPE,
  bankLaceDirection: SET_BANK_LACE_DIRECTION,
  labelPosition: SET_LABEL_POSITION,
  parts: SET_SELECTED_PARTS,
  all: SET_ALL_LEATHER_COLOR,
  beltType: SET_BELT_TYPE,
  catchFace: SET_LEATHER_COLOR,
  web: SET_WEB_COLOR,
  web2: SET_WEB_COLOR_2,
  edge: SET_EDGE_COLOR,
  thumbHook: SET_THUMB_HOOK,
  littleHook: SET_LITTLE_HOOK,
  lace: SET_LACE_COLOR,
  indexCover: SET_INDEX_COVER_COLOR,
  stitch: SET_STITCH_COLOR,
  linings: SET_LININGS,
  listLiningsMaterial: SET_LIST_LINING_MATERIAL,
  hamidashi: SET_HAMIDASHI,
  hamidashiOfCatchFace: SET_HAMIDASHI_OF_CATCH_FACE,
  mouton: SET_MOUTON_COLOR,
  atomsLabel: SET_ATOMS_LABEL,
  moldingOrder: SET_MOLDIGN_ORDER,

  // 内野手用グラブここから
  thumbOut: SET_THUMB_OUT_COLOR,
  thumbWeb: SET_THUMB_WEB_COLOR,
  thumbRoot: SET_THUMB_ROOT_COLOR,
  thumbWebAndIndexWeb: SET_THUMB_WEB_AND_INDEX_WEB,
  thumbIndexMiddle: SET_THUMB_INDEX_MIDDLE,
  thumbIndexMiddleRight: SET_THUMB_INDEX_MIDDLE_RIGHT,
  indexLeftMiddleRight: SET_INDEX_LEFT_MIDDLE_RIGHT_COLOR,
  middleLeftRingRight: SET_MIDDLE_LEFT_RING_RIGHT_COLOR,
  middleLeftRing: SET_MIDDLE_LEFT_RING,
  middleLeftRingLittle: SET_MIDDLE_LEFT_RING_LITTLE,
  ringLeftLittleRight: SET_RING_LEFT_LITTLE_RIGHT_COLOR,
  indexWeb: SET_INDEX_WEB_COLOR,
  indexMiddle: SET_INDEX_MIDDLE_COLOR,
  middleIndex: SET_MIDDLE_INDEX_COLOR,
  middleRing: SET_MIDDLE_RING_COLOR,
  ringMiddle: SET_RING_MIDDLE_COLOR,
  ringLittle: SET_RING_LITTLE_COLOR,
  littleRing: SET_LITTLE_RING_COLOR,
  littleOut: SET_LITTLE_OUT_COLOR,
  listBelt: SET_LIST_BELT_COLOR,
  loopOfRingFingerColor: SET_LOOP_OF_RING_FINGER_COLOR,

  // 一塁手用ミットここから
  thumb: SET_THUMB_COLOR,
  underWeb: SET_UNDER_WEB_COLOR,
  boomerang: SET_BOOMERANG_COLOR,
  // キャッチャーミットここから
  thumbMachi: SET_THUMB_MACHI_COLOR,
  littleMachi: SET_LITTLE_MACHI_COLOR,
  catchFaceTarget: SET_CATCH_FACE_TARGET_COLOR,
  indexAndMiddle: SET_INDEX_AND_MIDDLE_COLOR,
  fingerStand: SET_FINGER_STAND_COLOR,
  indexFingerTipOut: SET_INDEX_FINGER_TIP_OUT_COLOR
}
