export const imageBaseUrl = '/atoms/baseModels'
import { NextRouter } from 'next/router'
import { I18NText, Model } from 'src/types'
export const glovePriceTaxIncluded = 71500 // 税込
export const mittPriceTaxIncluded = 73700 // 税込
export const softGloveBasePrice = 55000 // 税込
export const softMittBasePrice = 57200 // 税込

export const atomsBaseModel = (t: I18NText, router: NextRouter): Model[] => {
  const urlParams = router.asPath.split('/')
  const seriesIndex = urlParams.length - 2
  const series = urlParams[seriesIndex] as 'japan' | 'us' | 'korea' | 'youth'

  const jaModels = series === 'japan' ? atomsJaBaseModels(t) : []
  const youthGloveModels = series === 'youth' && urlParams[urlParams.length - 1] === 'glove' ? youthGloveBaseModels(t) : []
  const youthMittModels = series === 'youth' && urlParams[urlParams.length - 1] === 'mitt' ? youthMittBaseModels(t) : []

  return [...jaModels, ...youthGloveModels, ...youthMittModels]
}

export const reinforcementLaceModel = [
  'AKG-JS4+',
  'AKG-JS5',
  'AKG-4',
  'AKG-5',
  'AKG-6',
  'AKG-14',
  'AKG-15',
  'AKG-16',
  'APL-UR2',
  'APL-UR4',
  'APL-UR5',
  'APL-UR10',
  'APL-UR026+',
  'APL-UR004+',
  'APL-UR005+',
  'APL-UR006+',
  'APL-UR007+',
  'APL-UR015+',
  'AGL-501',
  'AGL-503',
  'AGL-601',
  'AGL-603',
  'ATR-006'
]

const imageWidth = 240
const atomsJaBaseModels = (t: I18NText): Model[] => {
  return [
    {
      productNumber: 'AKG-1',
      subProductNumber: '2024-PRO46選手型',
      position: 'pitcher',
      imageUrl: `${imageBaseUrl}/akg-1.jpg`,
      dominantArm: 'both',
      size: t.AKG_1.size,
      webParts: 'w1',
      description: t.AKG_1.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },
    {
      productNumber: 'AKG-PRO46',
      position: 'pitcher',
      imageUrl: `${imageBaseUrl}/akg-pro46.jpg`,
      dominantArm: 'both',
      size: t.AKG_PRO46.size,
      webParts: 'w1',
      description: t.AKG_PRO46.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-11',
      position: 'pitcher',
      imageUrl: `${imageBaseUrl}/akg-11.jpg`,
      dominantArm: 'both',
      size: t.AKG_11.size,
      webParts: 'w2',
      description: t.AKG_11.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'ATR-001',
      position: 'pitcher',
      imageUrl: `${imageBaseUrl}/atr-001.jpg`,
      dominantArm: 'both',
      size: t.ATR_001.size,
      webParts: 'w5',
      description: t.ATR_001.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AKG-21',
      position: 'pitcher',
      imageUrl: `${imageBaseUrl}/akg-21.jpg`,
      dominantArm: 'both',
      size: t.AKG_21.size,
      webParts: 'w4',
      description: t.AKG_21.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 155
    },
    {
      productNumber: 'APL-UR066+',
      subProductNumber: '2024-PR02牧秀吾選手型',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur066+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR066_PLUS.size, // サイズ
      webParts: 'w32',
      description: t.APL_UR066_PLUS.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },

    {
      productNumber: 'APL-UR006+',
      subProductNumber: '2024-PRO39プロ選手型',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur006+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR006_PLUS.size, // サイズ
      webParts: 'w10',
      description: t.APL_UR006_PLUS.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'APL-UR026+',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur026+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR026_PLUS.size,
      webParts: 'w11',
      description: t.APL_UR026_PLUS.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 115
    },
    {
      productNumber: 'APL-UR004+',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur004+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR004_PLUS.size, // サイズ
      webParts: 'w11',
      description: t.APL_UR004_PLUS.description,
      series: '',
      isFingerCrotch: true,
      isUBack: true,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 115
    },
    {
      productNumber: 'APL-UR005+',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur005+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR005_PLUS.size, // サイズ
      webParts: 'w6',
      description: t.APL_UR005_PLUS.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 120
    },
    {
      productNumber: 'APL-UR015+',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur015+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR015_PLUS.size, // サイズ
      webParts: 'w26',
      description: t.APL_UR015_PLUS.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AKG-JS4+',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-js4+.jpg`,
      dominantArm: 'both',
      size: t.AKG_JS4_PLUS.size,
      webParts: 'w29',
      description: t.AKG_JS4_PLUS.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 120
    },
    {
      productNumber: 'AKG-JS5',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-js5.jpg`,
      dominantArm: 'both',
      size: t.AKG_JS5.size,
      webParts: 'w10',
      description: t.AKG_JS5.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 115
    },
    {
      productNumber: 'AKG-4',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-4.jpg`,
      dominantArm: 'both',
      size: t.AKG_4.size,
      webParts: 'w29',
      description: t.AKG_4.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-5',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-5.jpg`,
      dominantArm: 'both',
      size: t.AKG_5.size,
      webParts: 'w8',
      description: t.AKG_5.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-6',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-6.jpg`,
      dominantArm: 'both',
      size: t.AKG_6.size,
      webParts: 'w10',
      description: t.AKG_6.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-14',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-14.jpg`,
      dominantArm: 'both',
      size: 28.7,
      webParts: 'w9',
      description: '深めの設計。ボールを掴む感覚を重視するプレイヤー向けモデル',
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 135
    },
    {
      productNumber: 'AKG-15',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-15.jpg`,
      dominantArm: 'both',
      size: 29.0,
      webParts: 'w9',
      description: 'ポケットは深めで、強い打球にも負けない設計。ウェブの不要な部分をそち落として軽量化を図ったモデル',
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AKG-16',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-16.jpg`,
      dominantArm: 'both',
      size: t.AKG_16.size,
      webParts: 'w11',
      description: t.AKG_16.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-24',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/akg-24.jpg`,
      dominantArm: 'both',
      size: 28.5,
      webParts: 'w33',
      description: '内野手用小さめのサイズ。編み型ウェブを採用することで、さばきの柔軟性がUP',
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },
    {
      productNumber: 'APL-UR2',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur2.jpg`,
      dominantArm: 'both',
      size: t.APL_UR2.size,
      webParts: 'w24',
      description: t.APL_UR2.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 135
    },
    {
      productNumber: 'APL-UR3',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur3.jpg`,
      dominantArm: 'both',
      size: t.APL_UR2.size,
      webParts: 'w14',
      description: t.APL_UR2.description,
      series: '',
      isFingerCrotch: true,
      isUBack: true,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 135
    },
    {
      productNumber: 'APL-UR4',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur4.jpg`,
      dominantArm: 'both',
      size: t.APL_UR4.size,
      webParts: 'w11',
      description: t.APL_UR4.description,
      series: '',
      isFingerCrotch: true,
      isUBack: true,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 115
    },
    {
      productNumber: 'APL-UR5',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur5.jpg`,
      dominantArm: 'both',
      size: t.APL_UR5.size,
      webParts: 'w10',
      description: t.APL_UR5.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'APL-UR10',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/apl-ur10.jpg`,
      dominantArm: 'both',
      size: t.APL_UR10.size,
      webParts: 'w27',
      description: t.APL_UR10.description,
      series: '',
      isFingerCrotch: true,
      isUBack: true,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 110
    },
    {
      productNumber: 'AGL-105',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/agl-105.jpg`,
      dominantArm: 'both',
      size: t.AGL_105.size, // サイズ
      webParts: 'w13',
      description: t.AGL_105.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AGL-501',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/agl-501.jpg`,
      dominantArm: 'both',
      size: t.AGL_501.size, // サイズ
      webParts: 'w8',
      description: t.AGL_501.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },
    {
      productNumber: 'AGL-503',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/agl-503.jpg`,
      dominantArm: 'both',
      size: t.AGL_503.size, // サイズ
      webParts: 'w30',
      description: t.AGL_503.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 155
    },
    {
      productNumber: 'AGL-601',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/agl-601.jpg`,
      dominantArm: 'both',
      size: t.AGL_601.size, // サイズ
      webParts: 'w6',
      description: t.AGL_601.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AGL-603',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/agl-603.jpg`,
      dominantArm: 'both',
      size: t.AGL_603.size, // サイズ
      webParts: 'w6',
      description: t.AGL_603.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 135
    },
    {
      productNumber: 'ATR-006',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/atr-006.jpg`,
      dominantArm: 'both',
      size: t.ATR_006.size, // サイズ
      webParts: 'w31',
      description: t.ATR_006.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-7',
      subProductNumber: '2024-PRO41プロ選手型',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/akg-7.jpg`,
      dominantArm: 'both',
      size: t.AKG_7.size, // サイズ
      webParts: 'w14',
      description: t.AKG_7.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },
    {
      productNumber: 'AKG-JS7',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/akg-js7.jpg`,
      dominantArm: 'both',
      size: t.AKG_JS7.size, // サイズ
      webParts: 'w21',
      description: t.AKG_JS7.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 170
    },
    {
      productNumber: 'AKG-GT07',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/akg-gt07.jpg`,
      dominantArm: 'both',
      size: t.AKG_GT07.size, // サイズ
      webParts: 'w33',
      description: t.AKG_GT07.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },
    {
      productNumber: 'AKG-17',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/akg-17.jpg`,
      dominantArm: 'both',
      size: t.AKG_17.size, // サイズ
      webParts: 'w33',
      description: t.AKG_17.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AKG-27',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/akg-27.jpg`,
      dominantArm: 'both',
      size: t.AKG_27.size, // サイズ
      webParts: 'w19',
      description: t.AKG_27.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 155
    },
    {
      productNumber: 'ATR-007',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/atr-007.jpg`,
      dominantArm: 'both',
      size: t.ATR_007.size, // サイズ
      webParts: 'w23',
      description: t.ATR_007.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AGL-801',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/agl-801.jpg`,
      dominantArm: 'both',
      size: t.AGL_801.size, // サイズ
      webParts: 'w20',
      description: t.AGL_801.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 165
    },
    {
      productNumber: 'APL-UR6',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/apl-ur6.jpg`,
      dominantArm: 'both',
      size: t.APL_UR6.size, // サイズ
      webParts: 'w15',
      description: t.APL_UR6.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 120
    },
    {
      productNumber: 'APL-UR007+',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/apl-ur007+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR007_PLUS.size, // サイズ
      webParts: 'w23',
      description: t.APL_UR007_PLUS.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 115
    },
    {
      productNumber: 'APL-UR008+',
      position: 'outfielder',
      imageUrl: `${imageBaseUrl}/apl-ur008+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR008_PLUS.size, // サイズ
      webParts: 'w33',
      description: t.APL_UR008_PLUS.description,
      series: '',
      isFingerCrotch: true,
      isUBack: false,
      softPrice: softGloveBasePrice,
      price: t.BASE_PRICE_GLOVE,
      brand: 'atoms',
      imageWidth,
      imageHeight: 160
    },
    {
      productNumber: 'AKG-13',
      position: 'first',
      imageUrl: `${imageBaseUrl}/akg-13.jpg`,
      dominantArm: 'both',
      size: t.AKG_13.size, // サイズ
      webParts: '',
      description: t.AKG_13.description,
      series: 'domesticLine',
      isFingerCrotch: false,
      isUBack: false,
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 160
    },
    {
      productNumber: 'APL-UR003+',
      position: 'first',
      imageUrl: `${imageBaseUrl}/apl-ur003+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR003_PLUS.size, // サイズ
      webParts: '',
      description: t.APL_UR003_PLUS.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'belt',
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 110
    },
    {
      productNumber: 'AKG-GT33',
      position: 'first',
      imageUrl: `${imageBaseUrl}/akg-gt33.jpg`,
      dominantArm: 'both',
      size: t.AKG_GT33.size, // サイズ
      webParts: '',
      description: t.AKG_GT33.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'belt',
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 150
    },
    {
      productNumber: 'AKG-12',
      position: 'catcher',
      imageUrl: `${imageBaseUrl}/akg-12.jpg`,
      dominantArm: 'both',
      size: t.AKG_12.size, // サイズ
      webParts: '',
      description: t.AKG_12.description,
      series: 'domesticLine',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'regularBackStyle',
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 140
    },
    {
      productNumber: 'AKG-JS2',
      position: 'catcher',
      imageUrl: `${imageBaseUrl}/akg-js2.jpg`,
      dominantArm: 'both',
      size: t.AKG_JS2.size, // サイズ
      webParts: '',
      description: t.AKG_JS2.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'regularBackStyle',
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 130
    },
    {
      productNumber: 'APL-UR002+',
      position: 'catcher',
      imageUrl: `${imageBaseUrl}/apl-ur002+.jpg`,
      dominantArm: 'both',
      size: t.APL_UR002_PLUS.size, // サイズ
      webParts: '',
      description: t.APL_UR002_PLUS.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'beltBackStyle',
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 110
    },
    {
      productNumber: 'AKG-GT22',
      position: 'catcher',
      imageUrl: `${imageBaseUrl}/akg-gt22.jpg`,
      dominantArm: 'both',
      size: t.AKG_GT22.size, // サイズ
      webParts: '',
      description: t.AKG_GT22.description,
      series: '',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'beltBackStyle',
      softPrice: softMittBasePrice,
      price: t.BASE_PRICE_MITT,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    }
  ]
}

const youthGloveBaseModels = (t: I18NText): Model[] => {
  return [
    {
      productNumber: 'AGL-1001',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/youth/agl-1001.jpg`,
      dominantArm: 'both',
      size: 27.5,
      webParts: 'w11',
      description: '小学校高学年〜小さめ好みの大人まで使えるモデル',
      series: 'youth',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'beltBackStyle',
      price: t.BASE_PRICE_GLOVE,
      softPrice: softGloveBasePrice,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AGL-1004',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/youth/agl-1004.jpg`,
      dominantArm: 'both',
      size: 26.5,
      webParts: 'w11',
      description: '小学生向けグラブ。手の小さな中学生からの依頼で考案しました。',
      series: 'youth',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'beltBackStyle',
      price: t.BASE_PRICE_GLOVE,
      softPrice: softGloveBasePrice,
      brand: 'atoms',
      imageWidth,
      imageHeight: 155
    },
    {
      productNumber: 'AGL-1006',
      position: 'infielder',
      imageUrl: `${imageBaseUrl}/youth/agl-1006.jpg`,
      dominantArm: 'both',
      size: 29.8,
      webParts: 'w17',
      description: '小学校高学年〜小さめ好みの大人まで使えるモデル。',
      series: 'youth',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'beltBackStyle',
      price: t.BASE_PRICE_GLOVE,
      softPrice: softGloveBasePrice,
      brand: 'atoms',
      imageWidth,
      imageHeight: 160
    }
  ]
}

const youthMittBaseModels = (t: I18NText): Model[] => {
  return [
    {
      productNumber: 'AGL-2001',
      position: 'catcher',
      imageUrl: `${imageBaseUrl}/youth/agl-2001.jpg`,
      dominantArm: 'both',
      size: 30.0,
      webParts: '',
      description: '小学校高学年〜小さめ好みの大人まで使えるモデル。大人の方でトレーニング用・ブルペンミットとしてもご使用いただいています。',
      series: 'youth',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'regularBackStyle',
      price: t.BASE_PRICE_GLOVE,
      softPrice: softMittBasePrice,
      brand: 'atoms',
      imageWidth,
      imageHeight: 145
    },
    {
      productNumber: 'AGL-3001',
      position: 'first',
      imageUrl: `${imageBaseUrl}/youth/agl-3001.jpg`,
      dominantArm: 'both',
      size: 28.5,
      webParts: '',
      description: '小学校高学年〜小さめ好みの大人まで使えるモデル。手の小さな中学生からの依頼で考案しました。',
      series: 'youth',
      isFingerCrotch: false,
      isUBack: false,
      backStyle: 'belt',
      price: t.BASE_PRICE_GLOVE,
      softPrice: softMittBasePrice,
      brand: 'atoms',
      imageWidth,
      imageHeight: 155
    }
  ]
}

export const mockPitcherModel = (t: I18NText): Model[] => [
  {
    productNumber: 'AKG-1',
    position: 'pitcher',
    imageUrl: `${imageBaseUrl}/akg-1.jpg`,
    dominantArm: 'both',
    size: t.AKG_1.size,
    webParts: 'w1',
    description: t.AKG_1.description,
    series: '',
    isFingerCrotch: false,
    isUBack: false,
    softPrice: softGloveBasePrice,
    price: t.BASE_PRICE_GLOVE,
    brand: 'atoms',
    imageWidth,
    imageHeight: 150
  }
]
